
interface IEntityItem {
    id: number;
    code: string;
    name: string;
    alias: string;
    federalEmployerIDNumber: string | null;
    stateDisplayName: string | null;
    standardIndustryCode: string | null;
    countryName: string | null;
    stateId: number | null;
    countryId: number | null;
    standardIndustryId: number | null;
    hasClient: boolean;
    hasIssuer: boolean;
    hasProcessor: boolean;
    createBy: string,
    createDate: Date,
};

interface IUpsertEntityItem {
    id: number;
    code: string;
    name: string;
    alias: string;
    stateId: number;
    countryId: number;
    federalEmployerIDNumber: string | null;
    standardIndustryId: number;
    createBy: string,
    createDate: Date,
    type: {
        hasClient: boolean;
        hasIssuer: boolean;
        hasProcessor: boolean;
    };
}

enum EntityTypeEnum {
    Client = "Client",
    Issuer = "Issuer",
    Processor = "Processor",
}

enum EntityType {
    Client = 0,
    Issuer = 1,
    Processor = 2,
}

export { EntityTypeEnum, EntityType }
export type { IEntityItem, IUpsertEntityItem, }