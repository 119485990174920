import { verticalFormControlGridStyle } from "@styles/global-css-class";
import { FormState, IPropertyType, defaultCheckboxProperties, defaultDropDownProperties, defaultStringProperties } from "./definitions";
import { IEntityItem } from "./entitiesDefinitions";
import { string } from "zod";
import { IAddressInfo, IContactInfo } from "./issuerDefinitions";

interface IClientProperty {
    currencyCodeAlpha: string;
    doesCardCompliantDeliverNotices: boolean;
    doesCardCompliantReceiveResponses: boolean;
    doesCardCompliantProcessRTSMail: boolean;
    doesCardCompliantSignForms: boolean;
    doesCardCompliantSendForms: boolean;
    doesCardCompliantRemitForms: boolean;
    deliverPropertyListToClient: boolean;
    deliverEscheatFilingFilesToClient: boolean;
    runTaxReport: boolean;
    calendarEndDateToAdjust: Date;
    calendarStartDate: Date;
    taxPeriodEndDate: Date;
    approvedExceptionVariance: string;
    thirdPartyDistributorDefaultStateSold: string;
    thirdPriorityInternetDefaultStateSold: string;
    defaultStateSold: string;
    fiscalCalendarType: string;
    calendarNumberOfDaysToAdjust: number;
    doesCardCompliantServeAsFilingContact: boolean;
    doesCardCompliantReceiveResponsesResponseType: string;
    doesClientWantToEMailNotice: boolean;
};

interface IClientItem extends IClientProperty {
    id: number;
    clientCode: string;
    clientName: string;
    description: string;
    billingContactFirstName?: string;
    billingContactLastName?: string;
    billingContactPhone?: string;
    billingContactEmail?: string;
    billingAddress?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: string;
    billingZip?: string;
    companyType?: string;
    corporateFilingAddress?: string;
    corporateFilingAddress1?: string;
    corporateFilingAddress2?: string;
    corporateFilingCity?: string;
    corporateFilingContact1Address1?: string;
    corporateFilingContact1Address2?: string;
    corporateFilingContact1Address3?: string;
    corporateFilingContact1City?: string;
    corporateFilingContact1Country?: string;
    corporateFilingContact1Email?: string;
    corporateFilingContact1Fax?: string;
    corporateFilingContact1Name?: string;
    corporateFilingContact1Phone?: string;
    corporateFilingContact1State?: string;
    corporateFilingContact1Title?: string;
    corporateFilingContact1Zip?: string;
    corporateFilingContact2Address1?: string;
    corporateFilingContact2Address2?: string;
    corporateFilingContact2Address3?: string;
    corporateFilingContact2City?: string;
    corporateFilingContact2Country?: string;
    corporateFilingContact2Email?: string;
    corporateFilingContact2Fax?: string;
    corporateFilingContact2Name?: string;
    corporateFilingContact2Phone?: string;
    corporateFilingContact2State?: string;
    corporateFilingContact2Zip?: string;
    corporateFilingCountry?: string;
    corporateFilingCounty?: string;
    corporateFilingINCDate?: string;
    corporateFilingName?: string;
    corporateFilingNAICSCode?: string;
    corporateFilingNumberOfEmployees?: string;
    corporateFilingSICCode?: string;
    corporateFilingState?: string;
    corporateFilingTaxId?: string;
    corporateFilingTaxIdExt?: string;
    corporateFilingZip?: string;
    corporateFilingContact2Title?: string;
    corporateFilingFax?: string;
    corporateFilingINCState?: string;
    corporateFilingLastYearFiscalRevenue?: string;
    holderCorporateName?: string;
    monthsToEarnings?: string;
    principlePlaceOfBusinessAddress?: string;
    principlePlaceOfBusinessAddress2?: string;
    principlePlaceOfBusinessCity?: string;
    principlePlaceOfBusinessState?: string;
    principlePlaceOfBusinessZip?: string;
    technicalContactEmail?: string;
    technicalContactFirstName?: string;
    technicalContactLastName?: string;
    technicalContactPhone?: string;
    sic?: string;
    optiForDataRange?: string;
    status?: number;
    userId?: string;
    frequencyOfCardData?: string;
    margin?: string;
    optiForProcessExcludedCards?: string;
    escheatProduct?: string;
    runOptiEarnings?: boolean;
    applyProvisionalSeasonalAdjustment?: boolean;
    factorType?: string;
    feeStartMonth?: string;
    feeStopMonth?: string;
    primarilySellingTangiblePersonalPropertyAtRetail?: boolean;
    isQualifiedRestaurantRule?: boolean;
    inboundFileLocation?: string;
    inboundFileID?: string;
    inboundPushPull?: string;
    processorID?: string;
    entityId: number | null;
    issuerType: string;
    clientDisplayName: string;
    corporateFilingTotalAssets?: string;
    contractSignedDate: Date;
    statusName: string;
    notes: string;
    returnAddressID: number | null;
    responseAddressID: number | null;
    reportingPeriodStartMonthly: string;
    reportingPeriod: number | null;

    isParticipatingInBreakageForecasting?: boolean;
    activationsStartDate?: Date;
    activationsEndDate?: Date;
    isLessThanThreeYearsData?: boolean;
    isUsingIndustryCurvesLibrary?: boolean;
    isStartingProgramOnRemoteEarnings?: boolean;
    isProcessingExcludedCards?: boolean;
    isSkippingForecastingCalculation?: boolean;
    isBrickAndMortarRedemptions?: boolean;
    isOnlineRedemptions?: boolean;
    isWalletRedemptions?: boolean;
    isPhysicalMedium?: boolean;
    isVirtualEGiftMedium?: boolean;
    isWalletAccountPhysicalMedium?: boolean;
    isLoadedIntoAccountEGiftMedium?: boolean;
    isLoadedIntoAccountMedium?: boolean;
    monthFundsExpire?: string;
    monthPlasticExpires?: string;
    reloadableProgram?: boolean;
    industryType?: string;
    optiForNotes?: string;

    isParticipatingInBreakageAndEarnings?: boolean;
    reportFiscalYearEnd?: string;
    accountingMethod?: string;
    accountingPeriods?: string;
    derecognitionMethod?: string;
    trueUpMonth?: number;
    trueUpMethod?: string;
    earningsReserve?: number;
    recaptureReserve?: number;
    oneYearTaxDeferral?: boolean;
    isApplyPSA?: boolean;
    isFeeProgramApplicable?: boolean;
    isUpfrontOrActivationFee?: boolean;
    activationFeeAmount?: number;
    isIncludedInSoldAmountToCardCompliant?: boolean;
    isRecurringFees?: boolean;
    recurringFeeAmount?: number;
    frequency?: string;
    recurringFeeStartMonth?: string;
    feesStartBySoldMonthOrInactivity?: boolean;
    optiEarningNotes?: string;

    addresses: IAddressInfo[] | null;
    contacts: IContactInfo[] | null;
    customReportingPeriods?: ICustomReportingPeriod[];
    programs: IProgramItem[] | null;
    entity: IEntityItem | null;
};

interface ICustomReportingPeriod {
    id: number;
    index: number;
    startDate: string;
};

interface IClientProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    processorCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
};

interface IOrganizationItem {
    id: number;
    code: string;
    name: string;
    description: string;
    type: number;
    entityName: string;
    isDraft: boolean;
};

type OrganizationState = FormState & {
    id?: number;
    type?: number;
};

interface IAddOrganizationItem {
    code: string;
    name: string;
    description: string;
    type: number;
}

interface IIssuerItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    issuerType: string;
    entity: IEntityItem;
};


interface IProcessorItem {
    id: number;
    code: string;
    name: string;
    description: string;
    entityId: number | null;
    processorDisplayName: string;
    entity: IEntityItem;
};

interface IProgramProfileAttribute {
    id: number;
    attributeId: number;
    clientCode: string;
    programCode: string;
    attributeName: string;
    attributeValue: string;
    attributeType: string;
    active: boolean;
    createdDate: Date
};

interface IProgramItem {
    id: number;
    clientCode: string;
    programCode: string;
    processorCode: string;
    escheatProduct: string;
    frequencyOfCardData: string;
    name: string;
    programDescription: string;
    status: string;
    runTaxReport: boolean;
    leadIssuerId: number | null;
    otherIssuerId: number | null;
    leadIssuerType: string;
    otherIssuerType: string;
    cardCompliantClientNumber: string;
    programDisplayName: string;
    processorName: string;
    leadIssuerName: string;
    otherIssuerName: string;
    contractSignedDate: Date;

    doesCardCompliantDeliverNotices: boolean;
    doesCardCompliantReceiveResponses: boolean;
    currencyCodeAlpha: string;
};

interface INewProgramItem{
    clientCode: string;
    programCode: string;
    processorCode: string;
    leadIssuerId: number;
    otherIssuerId: number | null;
    status: string;
    currencyCodeAlpha: string;
    programDescription: string;
    name: string;
}

interface ICurrencyItem {
    currencyCodeAlpha: string;
    currencySymbol: string;
    currencyDisplayName: string;
};

const organizationTypes = [
    { name: 'Client', value: 0 },
    { name: 'Issuer', value: 1 },
    { name: 'Processor', value: 2 },
]

const parties = [
    { name: 'Card Compliant', value: true },
    { name: 'Client', value: false },
]

const organizationTypeEnum = ["Client", "Issuer", "Processor"];

const clientStringProperties: IPropertyType = {
    ...defaultStringProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

const clientCheckboxProperties: IPropertyType = {
    ...defaultCheckboxProperties,
    xsFormControl: 6,
    mdFormControl: 3,
    xsLabel: 12,
    textAlign: "left",
    itemSx: verticalFormControlGridStyle,
}

enum ProfileAttributeNameEnum {
    DoesCardCompliantDeliverNotices = "DoesCardCompliantDeliverNotices",
    DoesCardCompliantReceiveResponses = "DoesCardCompliantReceiveResponses",
    DoesClientWantToEMailNotice = "DoesClientWantToEMailNotice",
    DoesCardCompliantProcessRTSMail = "DoesCardCompliantProcessRTSMail",
    CurrencyCodeAlpha = "CurrencyCodeAlpha",
    DefaultStateSold = "DefaultStateSold",
    ThirdPriorityInternetDefaultStateSold = "ThirdPriorityInternetDefaultStateSold",
    ThirdPartyDistributorDefaultStateSold = "ThirdPartyDistributorDefaultStateSold",
    ApprovedExceptionVariance = "ApprovedExceptionVariance",
    DoesCardCompliantSignForms = "DoesCardCompliantSignForms",
    DoesCardCompliantSendForms = "DoesCardCompliantSendForms",
    DoesCardCompliantRemitForms = "DoesCardCompliantRemitForms",
    DeliverPropertyListToClient = "DeliverPropertyListToClient",
    DeliverEscheatFilingFilesToClient = "DeliverEscheatFilingFilesToClient",
    RunTaxReport = "RunTaxReport",
    CalendarEndDateToAdjust = "CalendarEndDateToAdjust",
    CalendarNumberOfDaysToAdjust = "CalendarNumberOfDaysToAdjust",
    CalendarStartDate = "CalendarStartDate",
    FiscalYearStartDate = "FiscalYearStartDate",
    TaxPeriodEndDate = "TaxPeriodEndDate",
    CardCompliantReturnAddress = "CardCompliantReturnAddress",
    ClientReturnAddress = "ClientReturnAddress",
    CardCompliantResponseType = "CardCompliantResponseType",
    FiscalCalendarType = "FiscalCalendarType",
}

type NewDraftClientFormState = FormState & {
    id?: number;
};

const clientStagingStatuses = [
    { name: 'New', value: 0 },
    { name: 'Sales', value: 1 },
    { name: 'Contracting', value: 2 },
    { name: 'Implementing', value: 3 },
]

const programStatuses = [
    { name: 'Sales', value:'Sales' },
    { name: 'Contracting', value: 'Contracting' },
    { name: 'Implementing', value: 'Implementing'},
]

enum ClientStagingStatusEnum {
    New = 0,
    Sales = 1,
    Contracting = 2,
    Implementing = 3,
    Draft = 4
}

type NewDraftFormState = FormState & {
    id?: number;
};

export {
    organizationTypeEnum,
    organizationTypes,
    clientStringProperties,
    clientDropDownProperties,
    clientCheckboxProperties,
    ProfileAttributeNameEnum,
    parties,
    clientStagingStatuses,
    ClientStagingStatusEnum,
    programStatuses,
}
export type { OrganizationState, }
export type {
    IClientItem,
    IOrganizationItem,
    IAddOrganizationItem,
    IIssuerItem,
    IProcessorItem,
    IProgramItem,
    IProgramProfileAttribute,
    ICurrencyItem,
    NewDraftFormState,
    ICustomReportingPeriod,
    INewProgramItem
}