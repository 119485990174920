import { FormControl, Grid, Autocomplete, TextField, MenuItem } from "@mui/material";
import React, { memo } from "react";
import { customDisabledOutlineInput, menuItemStyle, paddingX1Props } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IAutoCompleteFormControlProperty } from "@definitions/definitions";
import type { ListItem } from "@definitions/definitions";
function NewAutoCompleteFormControl({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isFreeSolo,
  disableClearable,
  ...props
}: Readonly<IAutoCompleteFormControlProperty>) {
  const sxThem = textSx ?? menuItemStyle;
  const selected = () => {
    if (isFreeSolo) {
      return value ?? '';
    }
    return listValues?.find(item => typeof item === 'object' ? value === (item as ListItem).value : value === item) ?? '';
  };
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewAutoCompleteFormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} required={isRequired} data-sentry-element="FormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
			<Autocomplete key={`${childKey}-autocomplete`} disablePortal freeSolo={isFreeSolo} options={listValues} disabled={isDisabled} disableClearable={disableClearable} value={selected()} isOptionEqualToValue={(option, newValue) => {
        if (typeof option === 'object' && typeof newValue === 'string') {
          return (option as ListItem).value === (newValue ?? '');
        } else if (typeof option === 'object' && typeof newValue === 'object') {
          return (option as ListItem).value === ((newValue as ListItem).value ?? '');
        }
        return option === newValue;
      }} getOptionKey={option => {
        if (typeof option === 'object') {
          return (option as ListItem).value;
        }
        return option;
      }} getOptionLabel={(option: any) => {
        if (typeof option === 'object') {
          return (option as ListItem).name;
        }
        return option;
      }} sx={{
        maxHeight: 200,
        "@media screen and (max-width: 1366px) and (max-height: 768px)": {
          maxHeight: 136
        },
        ...customDisabledOutlineInput
      }} onChange={(event: any, newValue: any) => {
        let isExisting = false;
        if (typeof newValue === 'object') {
          isExisting = listValues.some(item => typeof item === 'object' ? (newValue as ListItem).value === (item as ListItem).value : (newValue as ListItem).value === item);
        } else {
          isExisting = listValues.some(item => typeof item === 'object' ? newValue === (item as ListItem).value : newValue === item);
        }
        if (!isExisting && !isFreeSolo) {
          return onChange('');
        }
        if (typeof newValue === 'object') {
          onChange((newValue as ListItem).value ?? '');
          return;
        }
        onChange(newValue ?? '');
      }} renderInput={params => <TextField key={`${childKey}-input`} {...params} name={name} value={value} label={label} placeholder={showSelect ? `Select ${label?.toString()}` : label?.toString()} sx={sxThem} onChange={e => {
        if (isFreeSolo) {
          onChange(e.target.value);
        }
      }} />} componentsProps={{
        paper: {
          sx: {
            ...sxThem
          }
        }
      }} renderOption={(props, option) => {
        const {
          ...optionProps
        } = props;
        return typeof option === 'object' ? <MenuItem {...optionProps} sx={sxThem} key={`${name}-${(option as ListItem).value}`} value={(option as ListItem).value?.toString()}>
						{(option as ListItem).name}
					</MenuItem> : <MenuItem {...optionProps} sx={sxThem} key={`${name}-${option}`} value={option}>
						{option}
					</MenuItem>;
      }} data-sentry-element="Autocomplete" data-sentry-source-file="NewAutoCompleteFormControl.tsx" />
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewAutoCompleteFormControl);