import { DEFAULT_DATE, PHONE_REGEX_DASH } from "@constants/common";
import { UUID } from "crypto";
import { ReactNode } from "react";
import { z } from "zod";

export type LayoutProps = {
	children: ReactNode;
};

export type Props = {
	children?: ReactNode;
};

export type GlossaryItem = {
	id: UUID;
	name: string;
	definition: string;
	createDate: Date;
	lastModifyDate: Date;
};

export type GroupItem = {
	groupId: UUID;
	groupName: string;
	roleName: string;
};

export type EmbeddedReportQueryParams = {
	workspaceId: string;
	reportId: string;
	datasetIds?: string;
	paginatedReportIds?: string;
	rlsRoles?: RlsRole[];
};

export type RlsRole = {
	username: string;
	roles: string[];
	datasets: string[];
};

export type EmbeddedReportParams = {
	workspaceId: string;
	reportId: string;
	isRdlReport?: boolean;
	datasetIds?: string[];
	paginatedReportIds?: string[];
	rlsRoles?: RlsRole[];
};

export type EffectiveIdentity = {
	userName: string;
	roles: string[];
	datasets: string[];
};

export type RadioItem = {
	name: string;
	value: boolean;
};

export enum MessageType {
	Success = "success",
	Warning = "warning",
	Error = "error",
	Info = "info",
}

export enum MessageColor {
	Success = "green",
	Warning = "yellow",
	Error = "red",
	Info = "blue",
	Default = "black",
}

export const defineNullableOrMaxLength = (name: string, num: number = 50) => z.string().transform((t) => t?.trim())
	.pipe(z.string().max(num, `The ${name} exceeds the maximum allowable length of ${num} characters.`))
	.nullish();

export const defineNullableOrMinLength = (name: string, min: number = 50) => z.string().transform((t) => t?.trim())
	.pipe(z.string().min(min, `The ${name} must have ${min} character(s).`))
	.nullish();

export const defineNullableOrMinMaxLength = (name: string, min: number = 1, max: number = 50) => z.nullable(z.string().transform((t) => t?.trim())
	.pipe(z.string()
		.min(min, `The ${name} must have ${min} character(s).`)
		.max(max, `The ${name} exceeds the maximum allowable length of ${max} characters.`)));

export const defineNullableOrMaxLengthEmail = (name: string, num: number = 50) => z.nullable(z.string()
	.email({ message: "Invalid email address" })
	.max(num, `The ${name} exceeds the maximum allowable length of ${num} characters.`)
);

export const defineRequiredAndMaxLengthEmail = (name: string, num: number = 50) => z
	.string({
		invalid_type_error: `The email address is required.`,
		required_error: `The email address is required.`,
	})
	.transform((t) => t?.trim())
	.pipe(z.string()
	.max(num, `The ${name} exceeds the maximum allowable length of ${num} characters`)
	.email({ message: "Invalid email address" }));

export const defineNullableOrMaxLengthPhone = (name: string, num: number = 50) => z.nullable(z.string()
	.max(num, `The ${name} exceeds the maximum allowable length of ${num} characters.`)
	.refine((phone) => PHONE_REGEX_DASH.exec(phone), "Invalid phone number")
);

export const defineRequiredAndMaxLengthPhone = (name: string, num: number = 50) => z
	.string({
		invalid_type_error: `The phone is required.`,
		required_error: `The phone is required.`,
	})
	.transform((t) => t?.trim())
	.pipe(z.string()
	.max(num, `The ${name} exceeds the maximum allowable length of ${num} characters`)
	.refine((phone) => PHONE_REGEX_DASH.exec(phone), "Invalid phone number"));

export const defineNullableOrMaxNumber = (name: string, num: number = 1000000000000) => z.nullable(z.number()
	.max(num, `The ${name} cannot exceed ${num.toLocaleString("en-US")}`)
);

export const defineNullishOrDate = (name: string) => z.string()
	.refine((str) => {
		return str !== "Invalid Date";
	}, `The ${name} is invalid`)
	.transform((str) => {
		if (str.startsWith(DEFAULT_DATE)) {
			return null;
		}
		return new Date(str);
	})
	.nullish();

export const defineDate = (name: string) => z.string({
	invalid_type_error: `The ${name} is required.`,
	required_error: `The ${name} is required.`,
})
	.refine((str) => {
		return str !== "Invalid Date" && !str.startsWith(DEFAULT_DATE);
	}, `The ${name} is invalid`)
	.transform((str) => new Date(str));

export const defineRequiredMaxLength = (name: string, num: number = 50) => z.string({
	invalid_type_error: `The ${name} is required.`,
	required_error: `The ${name} is required.`
})
	.transform((t) => t?.trim())
	.pipe(z.string().min(1, `The ${name} is required.`))
	.pipe(z.string().max(num, `The ${name} exceeds the maximum allowable length of ${num} characters.`));

export const defineRequiredNumber = (name: string) => z.number({
	required_error: `The ${name} is required.`,
	invalid_type_error: `The ${name} is required.`,
});

export const defineNullableNumber = (name: string) => z.number({
	required_error: `The ${name} is required.`,
	invalid_type_error: `The ${name} is required.`,
}).nullable();

export const defineOptionalNullableNumber = (name: string) => 
	z.number().optional().nullable();

export const defineNullableBoolean = (name: string) => z.boolean({
	required_error: `The ${name} is required.`,
	invalid_type_error: `The ${name} is required.`,
}).nullable();

export const defineOptionalNullableBoolean = (name: string) => 
	z.boolean().optional().nullable();

export const defineRequireBoolean = (name: string) => z.boolean({
	required_error: `The ${name} is required.`,
	invalid_type_error: `The ${name} is required.`,
});

const RetailerSchema = z.object({
	id: z.number().nullable(),
	stateProfileID: z.number({
		required_error: "The state profile ID is required."
	}),
	genre: defineRequiredMaxLength("genre", 50),
	retailType: defineRequiredMaxLength("retail type", 50),
	exemptMinProvision: z.boolean(),
	exemptMinTrigger: defineNullableOrMaxLength("exempt min trigger"),
	exemptMinAmount: z.string().nullable(),
	abandonTrigger: defineNullableOrMaxLength("abandon trigger", 100),
	abandonStartMonth: defineNullishOrDate("abandon start month"),
	abandonPeriod: z.string().nullable(),
	abandonExpirePeriod: z.string().nullable(),
	expireProvision: z.boolean(),
	reverseExpireProvision: z.boolean(),
	cashBackProvision: z.boolean(),
	cashBackImpose: z.boolean(),
	cashBackImposeAmount: z.string().nullable(),
	programFeeProvision: z.boolean(),
	deemedRegisteredProvision: z.boolean(),
	escheatProvision: z.boolean(),
	escheatAmountTrigger: defineNullableOrMaxLength("escheat amount trigger"),
	escheatAmountMethod: defineNullableOrMaxLength("escheat amount method"),
	escheatAmount: z.string().nullable(),
	exemptIssuerPrimarilySellingTangiblePersonalPropertyAtRetail: z.boolean(),
	reportingYearStart: defineNullishOrDate("reporting year start"),
	reportingYearEnd: defineNullishOrDate("reporting year end"),
	filingDueDate: defineNullishOrDate("filing due date"),
	firstPriorityProvision: z.boolean(),
	noConsiderationExemption: z.boolean().nullable(),
	monetaryConsiderationExemption: z.boolean().nullable(),
	nonMonetaryConsiderationExemption: z.boolean().nullable(),
	expirationDateEscheatExemption: z.boolean().nullable(),
	derivativeRightsExemption: z.boolean().nullable(),
	promotionalExemption: z.boolean().nullable(),
	registeredOwnerRecipientOnly: z.boolean().nullable(),
	transitionCutoffDate: defineNullishOrDate("transition cut off date"),
	transitionFilingDate: defineNullishOrDate("transition filing date"),
	effectiveDate: defineNullishOrDate("effective date"),
	effectiveDateType: defineNullableOrMaxLength("effective date type"),
	regulatoryAuthorityEscheat: z.boolean().nullable(),
	qualifiedRestaurantExemptMinProvision: z.boolean().nullable(),
	qualifiedRestaurantExemptMinTrigger: defineNullableOrMaxLength("qualified restaurant exempt min trigger"),
	qualifiedRestaurantExemptMinAmount: z.string().nullable(),
	is1stPrioritySpecificExemption: z.boolean(),
	is1stPriorityImplicitExemption: z.boolean(),
	reloadProvision: z.boolean(),
	cashBackReloadProvision: z.boolean(),
	expressLapSilentForCashPaid: z.boolean(),
	pricePaidLapSilentForCashPaid: z.boolean(),
	abandonPeriodConditional: z.string().nullable(),
	escheatAmountConditional: z.string().nullable(),
	possMultiRetailExemption: z.boolean(),
	regulatoryAuthorityEscheatExpiry: z.boolean(),
	createDate: z.string(),
	createBy: z.string(),
});

const RetailerRequiredFieldsSchema = z.object({
	reportingYearStart: defineDate("reporting year start"),
	reportingYearEnd: defineDate("reporting year end"),
	filingDueDate: defineDate("filing due date"),
})

const NoticeSchema = z.object({
	id: z.number().nullable(),
	stateProfileID: z.number({
		required_error: "The state profile ID is required."
	}),
	noticeThresholdAmount: z.string().nullable(),
	statuteOfLimitations: defineNullableOrMaxLength("statute of limitations"),
	earliestToSend: z.string().nullable(),
	latestToSend: z.string().nullable(),
	noticeRequired: z.boolean(),
	allowsEmail: z.boolean().nullable(),
	requiresCertifiedMail: z.boolean().nullable(),
	certifiedMailThreshold: z.string().nullable(),
	requiresUSMailAndEnvelope: z.boolean().nullable(),
	requiresCertifiedMailAndEnvelope: z.boolean().nullable(),
	requiresPublication: z.boolean().nullable(),
	publicationThreshold: z.string().nullable(),
	requiresUSMail: z.boolean().nullable(),
	stateProfile_RetailerKey: z.number().nullable(),
	earliestPublication: z.string().nullable(),
	createDate: z.string(),
	createBy: z.string(),
});

const FilingSchema = z.object({
	id: z.number().nullable(),
	stateProfileID: z.number({
		required_error: "The state profile ID is required."
	}),
	aggregateMax: z.string().nullable(),
	relationToOwner: defineNullableOrMaxLength("relation to owner"),
	mediaType: defineNullableOrMaxLength("media type"),
	propertyType: defineNullableOrMaxLength("property type"),
	aggPropertyType: defineNullableOrMaxLength("agg property type"),
	deductionCode: defineNullableOrMaxLength("deduction code"),
	encryptionRequired: z.boolean().nullable(),
	genre: defineNullableOrMaxLength("genre"),
	negativeFilingRequired: z.boolean().nullable(),
	negativeFilingPermitted: z.boolean().nullable(),
	negativeFilingProhibited: z.boolean().nullable(),
	amexPropertyType: defineNullableOrMaxLength("amex property type"),
	createDate: z.string(),
	createBy: z.string(),
});

const StateProfileSchema = z.object({
	id: z.number().nullable(),
	profileId: defineRequiredMaxLength("profile name", 50),
	stateId: defineRequiredMaxLength("state id", 50),
	domicileDefinition: defineRequiredMaxLength("domicile definition", 50),
	thirdPriorityProvision: z.boolean(),
	adminDeductionProvision: z.boolean().nullable(),
	adminDeductionMethod: defineNullableOrMaxLength("admin deduction method"),
	adminDeductionAmount: z.string().nullable(),
	adminDeductionPercentage: z.string().nullable(),
	legalAnnotation: z.string().nullable(),
	lastKnownAddressDefinition: z.boolean(),
	copiedFrom: defineNullableOrMaxLength("copied from", 20),
	isGeneral2ndPriorityExemption: z.boolean(),
	isStateSpecific2ndPriorityExemption: z.boolean(),
	originStateProfileID: z.number().nullable(),
	retailer: RetailerSchema.merge(RetailerRequiredFieldsSchema).nullable(),
	notice: NoticeSchema.nullable(),
	filing: FilingSchema.nullable(),
	createDate: z.string(),
	createBy: z.string(),
});

const DraftSchema = z.object({
	id: z.number().nullable(),
	profileId: defineRequiredMaxLength("profile name", 50),
	stateId: defineRequiredMaxLength("state id", 50),
	domicileDefinition: defineNullableOrMaxLength("domicile definition", 50),
	thirdPriorityProvision: z.boolean(),
	adminDeductionProvision: z.boolean().nullable(),
	adminDeductionMethod: defineNullableOrMaxLength("admin deduction method"),
	adminDeductionAmount: z.string().nullable(),
	adminDeductionPercentage: z.string().nullable(),
	legalAnnotation: z.string().nullable(),
	lastKnownAddressDefinition: z.boolean(),
	copiedFrom: defineNullableOrMaxLength("copied from", 20),
	isGeneral2ndPriorityExemption: z.boolean(),
	isStateSpecific2ndPriorityExemption: z.boolean(),
	originStateProfileID: z.number().nullable(),
	retailer: RetailerSchema.nullable(),
	notice: NoticeSchema.nullable(),
	filing: FilingSchema.nullable(),
	createDate: z.string(),
	createBy: z.string(),
});

const PublishDraftSchema = z.object({
	stateProfileId: z.number({
		invalid_type_error: "The state profile id is required."
	}),
	retailType: z.string().nullish(),
	genre: z.string().nullish(),
})

const NewDraftSchema = z.object({
	stateId: z.string({
		invalid_type_error: "The state is required."
	}),
	profileId: z.string({
		invalid_type_error: "The profile name is required."
	}),
	retailType: z.string().nullable(),
	genre: z.string().nullable(),
})


const ProgramProfileAttribute = z.object({
	id: z.number(),
	attributeId: z.number(),
	programCode: z.string(),
	clientCode: z.string(),
	attributeName: z.string(),
	attributeValue: z.string(),
	attributeType: z.string().nullable(),
	active: z.boolean(),
	createdDate: z.string().nullable()
});



const UpsertEntitySchema = z.object({
	id: z.number().nullable(),
	code: defineRequiredMaxLength("Entity Code", 20),
	name: defineRequiredMaxLength("Entity Name", 200),
	alias: defineNullableOrMaxLength("Alias", 200),
	federalEmployerIDNumber: z.string().transform((t) => t?.trim())
		.refine((fein) => {
			return fein.length !== 10;
		}, `The Federal Employer ID Number must be has length of 10 characters.`)
		.or(z.string().nullable()),
	standardIndustryId: z.number({
		required_error: `The standard industry ID is required.`,
		invalid_type_error: `The standard industry ID required.`,
	}).nullable(),
	stateId: defineRequiredNumber("State/Province"),
	countryId: defineRequiredNumber("Country"),
	createBy: z.string().nullish(),
	createDate: z.string().nullish(),
	type: z.object({
		hasClient: z.boolean(),
		hasIssuer: z.boolean(),
		hasProcessor: z.boolean(),
	})
		.refine((t) => {
			return t.hasClient === true || t.hasIssuer === true || t.hasProcessor === true
		}, "The Type has a minimum of 1"),
});

const IssuerSchema = z.object({
	id: z.number(),
	issuerCode: defineRequiredMaxLength("code", 200),
	name: defineRequiredMaxLength("name", 200),
	description: defineNullableOrMaxLength("description", 200),
	entityId: z.number().nullable(),
	issuerType: defineRequiredMaxLength("issuer type", 200),
});

const ProcessorSchema = z.object({
	id: z.number(),
	processorCode: defineRequiredMaxLength("code", 200),
	name: defineRequiredMaxLength("name", 200),
	description: defineNullableOrMaxLength("description", 200),
	entityId: z.number().nullable(),
});

const VerifyAccountSchema = z.object({
	clientCode: z.string().nullish(),
	accountUUID: defineRequiredMaxLength('account id', 200),
	escheatableDueDate: z.string().nullish(),
});

const isUUID = (uuidString: string) => {
	const uuidPattern =
		/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
	return uuidPattern.test(uuidString);
};

const GlossarySchema = z.object({
	id: z
		.string()
		.nullable()
		.refine((value) => value === null || isUUID(value), {
			message: "Id must be a valid UUID",
		}),
	name: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "Term is required")),
	definition: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "Term definition is required")),
});

const SimulationConditionSchema = z.object({
	clientCode: defineRequiredMaxLength('client code', 200),
	programCode: defineRequiredMaxLength('program code', 200),
	description: z.string().nullish(),
	reportDateToProcess: defineRequiredMaxLength('report date to process', 200),
	states: z.array(z.string()),
});


export {
	RetailerSchema,
	NoticeSchema,
	FilingSchema,
	StateProfileSchema,
	PublishDraftSchema,
	NewDraftSchema,
	UpsertEntitySchema,
	IssuerSchema,
	ProcessorSchema,
	GlossarySchema,
	DraftSchema,
	VerifyAccountSchema,
	SimulationConditionSchema,
}