import { FormState } from ".";

interface IEscheatExclusionReason {
    id: number;
    exclusionReasonName: string;
    exclusionReasonDescription: string;
    active: boolean;
}

interface IChangeStatusOfFilingsAndNotices {
    accountReferenceIDs: string[];
    escheatStatusId: EscheatStatus;
    exclusionReasonId?: number;
    responseReceivedDate?: string;
};

interface IFilingsAndNotices {
    clientCode: string;
    escheatableState: string;
    escheatableStatus: string;
    escheatableAmount: number;
    escheatableDate: Date | null;
    escheatableDueDate: Date | null;
    responseDueDate: Date | null;
    accountReferenceID: string;
    purchaserName: string;
    purchaserAddress: string;
    recipientName: string;
    recipientAddress: string;
    statusId: EscheatStatus;
    originalEscheatableDueDate: Date | null;
    returnedToSenderMailReceived: string;
    noticeResponseReceivedDate: Date | null;
    noticePdf: string;
    noticeAggPdf: string;
    naupaFiles?: string[],
    stateFiles?: string[],
    propertyId: string;
    hasGroupDocuments: boolean;
}

interface IVerifyAccountItem {
    clientCode: string | null;
    escheatableDueDate: string | null;
    accountUUID: string;
}

interface IAdvanceFilter {
    clientCode: string;
    escheatableDueDate?: string;
    filingPeriod?: string;
}

enum EscheatStatus {
    EscheatReady = 1,
    NoticesDraft = 2,
    NoticesDraftReady = 3,
    NoticesReady = 4,
    NoticesRejected = 5,
    NoticesSent = 6,
    NoticesResponseReceived = 7,
    FilingNoResponse = 8,
    FilingDraft = 9,
    FilingReadyForUpload = 10,
    FilingReadySubmit = 11,
    FilingRemitted = 12,
    FilingComplete = 13,
    PublicationDraftReady = 14,
    AmexDrawdown = 15,
    EscheatExcluded = 16,
}

enum JobRunStatus {
    Unknown = 0,
    NotStarted = 1,
    InProcessing = 2,
    Success = 3,
    Failed = 4,
    HasError = 5,
}

export enum FilingFileType {
    Unknown = 0,
    NoticePdf = 1,
    NoticeAggregatePdf = 2,

    NaupaText = 10,
    StateSummaryPdf = 11,
    NaupaTextAndStateSummaryZip = 12,
}

enum EscheatStatusName {
    EscheatReady = 'Escheat - Ready',
    NoticesDraft = 'Notices - Draft',
    NoticesDraftReady = 'Notices - Draft Ready',
    NoticesReady = 'Notices - Ready',
    NoticesRejected = 'Notices - Rejected',
    NoticesSent = 'Notices - Sent',
    NoticesResponseReceived = 'Notices - Response Received',
    FilingNoResponse = 'Filing - No Response',
    FilingDraft = 'Filing - Draft',
    FilingReadyForUpload = 'Filing - Ready For Upload',
    FilingReadySubmit = 'Filing - Ready To Submit',
    FilingRemitted = 'Filing - Remitted',
    FilingComplete = 'Filing - Complete',
    PublicationDraftReady = 'Publication - Draft Ready',
    AmexDrawdown = 'Amex - Drawdown',
    EscheatExcluded = 'Escheat - Excluded',
}

enum EscheatFilterGroup {
    Escheatable,
    NoticesAndPublications,
    Exclusion,
    FilingManagement,
    ResponseReceived
};

export const getEscheatStatusesByFilterGroup = (group: EscheatFilterGroup) => {
    switch (group) {
        case EscheatFilterGroup.Escheatable:
            return [EscheatStatus.EscheatReady];
        case EscheatFilterGroup.NoticesAndPublications:
            return [
                EscheatStatus.NoticesDraft,
                EscheatStatus.NoticesDraftReady,
                EscheatStatus.NoticesReady,
                EscheatStatus.NoticesSent,
                EscheatStatus.PublicationDraftReady
            ];
        case EscheatFilterGroup.Exclusion:
            return [
                EscheatStatus.NoticesRejected,
                EscheatStatus.EscheatExcluded];
        case EscheatFilterGroup.FilingManagement:
            return [
                EscheatStatus.FilingComplete,
                EscheatStatus.FilingDraft,
                EscheatStatus.FilingNoResponse,
                EscheatStatus.FilingReadyForUpload,
                EscheatStatus.FilingReadySubmit,
                EscheatStatus.FilingRemitted,
            ];
        case EscheatFilterGroup.ResponseReceived:
            return [EscheatStatus.NoticesResponseReceived];
    }
    return [];
}

export const CAN_APPROVE_STATUSES = [
    EscheatStatus.NoticesDraftReady,
];

export const CAN_NOTICES_SENT_STATUSES = [
    EscheatStatus.NoticesReady,
];

export const CAN_EXCLUDE_STATUSES = [
    EscheatStatus.EscheatReady,
    EscheatStatus.NoticesDraft,
    EscheatStatus.NoticesDraftReady,
    EscheatStatus.NoticesReady,
    EscheatStatus.NoticesResponseReceived,
    EscheatStatus.NoticesSent,
    EscheatStatus.FilingDraft,
    EscheatStatus.FilingNoResponse,
    EscheatStatus.PublicationDraftReady,
];

type VerifiedAccountState = FormState & {
    clientCode?: string;
    accountReferenceID?: string;
    totalRecords?: number;
    escheatableState?: string;
    escheatableAmount?: number;
    escheatableDueDate?: Date | null;
};

enum ExclusionReasonIds {
    StaleData = 1,
    OtherContract = 2,
    AdditionalTransactions = 3,
    Fraud = 4,
    AnonymousMaskedPII = 5,
    AccountVerified = 6,
    ResponseReceived = 7,
}

interface IGenerateNoticesDocumentsItem {
    clientCode: string | null;
    propertyIds: string[] | null;
}

export { EscheatStatus, EscheatStatusName, EscheatFilterGroup, ExclusionReasonIds, JobRunStatus }
export type { IEscheatExclusionReason, IChangeStatusOfFilingsAndNotices, IFilingsAndNotices, IVerifyAccountItem, VerifiedAccountState, IAdvanceFilter, IGenerateNoticesDocumentsItem, }