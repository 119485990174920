import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import { memo } from "react";
import { StyledFormLabel } from "@components/Common";
import { IRadioFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { flexCenterProps, flexRowGap1Props, horizontalFormControlGridLeftStyle, paddingX1Props, requiredLabelStyle } from "@styles/global-css-class";
const NewRadioGroupFormControl = ({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  listValues,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isHideLabel,
  labelToolTip,
  isVertical,
  isLabelToolTip,
  isInputToolTip,
  afterLabelComponent,
  labelPlacement = "end",
  radioInline = false,
  isShowRequiredAsterisk = true,
  ...props
}: IRadioFormControlProperty) => {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    py: "7px",
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewRadioGroupFormControl" data-sentry-source-file="NewRadioGroupFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' sx={{
      marginBottom: "4px",
      marginTop: "6px"
    }} {...props} data-sentry-element="FormControl" data-sentry-source-file="NewRadioGroupFormControl.tsx">
			<Grid container item sx={flexCenterProps} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
				<Grid container item xs={xsLabel} sx={horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
					<StyledFormLabel aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="NewRadioGroupFormControl.tsx">
						{isLabelToolTip ? <Tooltip title={labelToolTip} placement="right-start">
								<Typography>
									{label ?? ''}
								</Typography>
							</Tooltip> : label}
						{afterLabelComponent}
						{isRequired && isShowRequiredAsterisk && '*'}
					</StyledFormLabel>
				</Grid>
				<Grid container item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={flexRowGap1Props} data-sentry-element="Grid" data-sentry-source-file="NewRadioGroupFormControl.tsx">
					{isInputToolTip ? <Tooltip title={labelToolTip} placement="right-start">
							<RadioGroup key={`${childKey}-form-control-radio-group`} id={`${name}-radio-group`} aria-labelledby={`${name}-radio-buttons-group-label`} value={value ?? defaultValue ?? ''} name={`${name}-radio-buttons-group`} onChange={e => {
              onChange(e.target.value);
            }}>
								<Grid container item xs={12}>
									{listValues?.map(item => <Grid key={`${name}-${item.value}`} item xs={isVertical ? 12 : 6} sx={{
                  textAlign: isVertical ? "left" : "center"
                }}>
											<FormControlLabel key={`${childKey}-form-control-label-${item.value}`} sx={{
                    m: 0
                  }} value={item.value} control={<Radio key={`${childKey}-form-control-radio-${item.name}`} id={`${name}-radio-${item.name}`} name={item.name} disabled={isDisabled} color="default" />} label={isHideLabel === true ? "" : item.name} />
										</Grid>)}
								</Grid>

							</RadioGroup>
						</Tooltip> : <RadioGroup key={`${childKey}-form-control-radio-group`} id={`${name}-radio-group`} aria-labelledby={`${name}-radio-buttons-group-label`} value={value ?? defaultValue ?? ''} name={`${name}-radio-buttons-group`} onChange={e => {
            onChange(e.target.value);
          }}>
							<Grid container item xs={12} sx={radioInline ? {
              display: "ruby !important"
            } : {}}>
								{listValues?.map(item => <Grid key={`${name}-${item.value}`} item xs={isVertical ? 12 : 6} sx={{
                textAlign: isVertical ? "left" : "center"
              }}>
										<FormControlLabel key={`${childKey}-form-control-label-${item.value}`} sx={{
                  m: 0
                }} value={item.value} control={<Radio key={`${childKey}-form-control-radio-${item.name}`} id={`${name}-radio-${item.name}`} name={item.name} disabled={isDisabled} color="default" />} label={isHideLabel === true ? "" : item.name} labelPlacement={labelPlacement} />
									</Grid>)}
							</Grid>

						</RadioGroup>}
				</Grid>
				{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
};
export default memo(NewRadioGroupFormControl);