import { IDataLayoutItem } from "./dataLayoutDefinitions";
import { defaultAutoCompleteProperties, defaultDropDownProperties, defaultStringProperties, FormState, IPropertyType } from "./definitions";

interface IDataStreamItem {
    id: number;
    dataLayoutId: number | null;
    sourceSystemId: number | null;
    fileAsOfDateMappingId: number | null;
    clientCode: string | null;
    clientName: string | null;
    createdBy: string | null;
    createdDate: string | null;
    processorCode: string | null;
    programCode: string | null;
    processorName: string | null;
    dataStreamName: string;
    dataLayoutName: string;
    brandCode: string;
    silverTransactionDeduplication: boolean | null;
    fileNameMappings: string;
    fileNamePattern: string;
    fileNamePatternExclude: string;
    filePath: string;
    dataStreamType: DATA_STREAM_TYPES,
    isCompressed: boolean | null;
    isPasswordProtected: boolean | null;
    cardStatusCodeMappingGroupId: number | null;
    transactionCodeMappingGroupId: number | null;
    dataStreamGroup: string;

    dataLayout: IDataLayoutItem | null;
    sourceSystem: ISourceSystemItem | null;
    fileAsOfDateMapping: IFileAsOfDateMappingItem | null;
}

interface ISourceSystemItem {
    id: number;
    description: string;
    ingestionType: string;
    rootPath: string;
    connectionSecret: string;
    connectionKey: string;
    active: boolean | null;
    createdBy: string | null;
    createdDate: string | null;
}

interface IFileAsOfDateMappingItem {
    id: number;
    fileAsOfDateMapping: string;
    createdBy: string | null;
    createdDate: string | null;
}

export const dataStreamResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const dataStreamStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...dataStreamResponsiveProps,
}

const FILE_FORMATS = {
    DELIMITED: "Delimited",
    DSR: "DSR",
    EMAF: "EMAF",
    ENCRYPTED_DELIMITED: "EncryptedDelimited",
    EXCEL: "Excel",
    FIXED_WIDTH: "FixedWidth",
    XML: "XML",
};

enum DATA_STREAM_TYPES {
    Published,
    Draft,
};

type NewDraftDataStreamFormState = FormState & {
    id?: number;
};

interface INewDraftDataStreamItem {
    dataStreamName: string;
}

export { DATA_STREAM_TYPES, FILE_FORMATS, }
export type {
    IDataStreamItem,
    NewDraftDataStreamFormState,
    INewDraftDataStreamItem,
    ISourceSystemItem,
    IFileAsOfDateMappingItem,
}