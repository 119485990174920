"use client";

import { Box, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { DefaultButton } from "@components/Common";
import ClearIcon from '@mui/icons-material/Clear';
import { NotificationListItem } from "./NotificationListItem";
import { ActionStatus, EMPTY_FORM_STATE, INotificationItem, NotificationActionType, NotificationItem, NotificationType } from "@definitions/index";
import { borderBottomStyles, colors } from "@components/ThemeRegistry/theme";
import { fetchUserNotificationsAsync, markAsReadAsync } from "@lib/notifications/notifications-service";
import { AuthorizationContext } from "@components/AuthorizationContext";
import { UrlObject } from "url";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { ROUTE } from "@constants/common";
import { approvalAsync } from "@lib/reviews-and-approvals/reviews-and-approvals-service";
import { Route } from "next";
const AppNotifications: FC<{
  handleClose: Function;
  handleRefreshCount: Function;
}> = ({
  handleClose,
  handleRefreshCount
}) => {
  const authenticatedContext = useContext(AuthorizationContext);
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const router = useRouter();
  const newNotifications: NotificationItem[] = notifications.filter(x => x.isNew);
  const earlierNotifications: NotificationItem[] = notifications.filter(x => !x.isNew);
  const handleAction = (actionType: NotificationActionType, notification: INotificationItem) => {
    switch (actionType) {
      case NotificationActionType.MarkAsRead:
        {
          notification.isRead = true;
          markAsRead([notification.id]);
          return;
        }
      case NotificationActionType.Open:
        {
          navigateToApprovePage(notification.type);
          return;
        }
      case NotificationActionType.Download:
        {
          handleDownload(notification);
          return;
        }
      case NotificationActionType.Approve:
        {
          handleApprove(notification);
          return;
        }
    }
  };
  const navigateToApprovePage = (type: NotificationType) => {
    switch (type) {
      case NotificationType.RequestApproval:
        router.push("/reviews-and-approvals");
        break;
      case NotificationType.ManuallyGenerateNoticesDocuments:
        router.push(ROUTE.FILINGS_NOTICES as Route);
        break;
      default:
        break;
    }
  };
  const handleApprove = async (notification: INotificationItem) => {
    switch (notification.type) {
      case NotificationType.RequestApproval:
        {
          setIsFormSubmitted(true);
          const result = await approvalAsync(authenticatedContext.refreshToken(), EMPTY_FORM_STATE, notification.relatedId);
          if (result.key === ActionStatus.SUCCESS) {
            notification.isRead = true;
            notification.isApprovalRequestPending = false;
            markAsRead([notification.id]);
            setIsFormSubmitted(false);
          }
        }
      default:
        break;
    }
  };
  const markAsRead = (notificationIds: number[] = []) => {
    if (!notificationIds || notificationIds.length === 0) {
      //Mark all as read
      notificationIds = [];
      newNotifications.forEach(item => {
        if (!item.isRead) {
          notificationIds.push(item.id);
          item.isRead = true;
        }
      });
      earlierNotifications.forEach(item => {
        if (!item.isRead) {
          notificationIds.push(item.id);
          item.isRead = true;
        }
      });
    }
    if (notificationIds.length > 0) {
      markAsReadAsync(authenticatedContext.refreshToken(), () => {
        handleRefreshCount();
      }, notificationIds);
    }
  };
  const handleDownload = async (notification: INotificationItem) => {
    console.log("Download link", notification.data?.link); //TODO: implement download function
  };
  useEffect(() => {
    handleRefreshCount(); //Refresh count when opening the notification
    fetchUserNotificationsAsync(authenticatedContext.refreshToken(), (notifications: INotificationItem[]) => {
      if (!notifications || notifications.length == 0) {
        setNotifications([]);
      }
      const mappedNotifications = notifications.map(x => new NotificationItem(x));
      setNotifications(mappedNotifications);
    });
  }, []);
  return <Box sx={{
    minWidth: "400px",
    maxWidth: "400px",
    p: 3
  }} data-sentry-element="Box" data-sentry-component="AppNotifications" data-sentry-source-file="AppNotifications.tsx">
				<Box sx={{
      display: "flex",
      justifyContent: "space-between",
      mb: 1,
      pb: 0,
      ...borderBottomStyles
    }} data-sentry-element="Box" data-sentry-source-file="AppNotifications.tsx">
					<Box data-sentry-element="Box" data-sentry-source-file="AppNotifications.tsx">
						<Typography fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="AppNotifications.tsx">
							Notifications
						</Typography>
					</Box>
					<Box data-sentry-element="Box" data-sentry-source-file="AppNotifications.tsx">
						<ClearIcon sx={{
          fontSize: "18px",
          cursor: "pointer"
        }} onClick={() => {
          handleClose();
        }} data-sentry-element="ClearIcon" data-sentry-source-file="AppNotifications.tsx"></ClearIcon>
					</Box>
				</Box>

				<Box sx={{
      maxHeight: "calc(90vh - 135px);",
      overflowY: "auto"
    }} data-sentry-element="Box" data-sentry-source-file="AppNotifications.tsx">
					{newNotifications?.length > 0 && <Box sx={{
        pt: 1,
        pb: 1
      }}>
							<Box sx={{
          ...borderBottomStyles,
          mb: 2,
          pb: 1
        }}>
								<Typography fontWeight="bold">
									New
								</Typography>

							</Box>

							<Box>
								{newNotifications.map(notification => {
            return <NotificationListItem key={notification.id} data={notification} handelAction={handleAction} isFormSubmitted={isFormSubmitted} />;
          })}
							</Box>
						</Box>}

					{earlierNotifications?.length > 0 && <Box sx={{
        pt: 1
      }}>
							<Box sx={{
          ...borderBottomStyles,
          mb: 2,
          pb: 1
        }}>
								<Typography fontWeight="bold">
									Earlier
								</Typography>

							</Box>
							<Box>
								{earlierNotifications.map(notification => {
            return <NotificationListItem key={notification.id} data={notification} handelAction={handleAction} isFormSubmitted={isFormSubmitted} />;
          })}
							</Box>
						</Box>}

				</Box>

				{notifications?.length > 0 && <Box sx={{
      display: "flex",
      justifyContent: "space-between",
      pt: 3,
      pb: 0
    }}>
						<Box>
							{notifications.some(x => !x.isRead) && <DefaultButton sx={{
          maxHeight: "25px",
          px: "15px",
          minWidth: " 100px"
        }} onClick={() => {
          markAsRead();
        }}>
									Mark All As Read
								</DefaultButton>}

						</Box>
						<Box>
							<Link href={ROUTE.NOTIFICATIONS as unknown as UrlObject} style={{
          cursor: "pointer",
          color: colors.navy,
          fontWeight: "500"
        }}>
								See more...
							</Link>
						</Box>
					</Box>}
			</Box>;
};
export default AppNotifications;