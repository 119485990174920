import { models } from "powerbi-client";

export const PBI_REPORT_FILTERS_STORAGE_KEY = 'PowerBiFilters';
export const PBI_REPORT_VISUAL_FILTERS_STORAGE_KEY = 'PowerBiVisualFilters';
export const PBI_REPORT_BASIC_FILTER_SCHEMA = 'http://powerbi.com/product/schema#basic';
export const PBI_REPORT_ADVANCED_FILTER_SCHEMA = 'http://powerbi.com/product/schema#advanced';

export const PBI_REPORT_DEFAULT_FILTER_NAME = {
    Client: "Client",
    Program: "Program",
    ReportingPeriod: "Reporting Period"
};

export interface IDefaultReportFilter {
    displayName: string,
    values: any[]
};

export class PowerBIReportFilterStorage {
    constructor() { }

    public static storeReportFilters(filters: models.IFilter[]) {
        sessionStorage.setItem(PBI_REPORT_FILTERS_STORAGE_KEY, JSON.stringify(filters));
    }

    public static storeReportVisualFilters(filters: models.IFilter[]) {
        sessionStorage.setItem(PBI_REPORT_VISUAL_FILTERS_STORAGE_KEY, JSON.stringify(filters));
    }

    //Clear session storage
    public static clearFilterStorages(): void {
        sessionStorage.removeItem(PBI_REPORT_FILTERS_STORAGE_KEY);
        sessionStorage.removeItem(PBI_REPORT_VISUAL_FILTERS_STORAGE_KEY);
    }

    public static hasStoredFilters() {
        const storageFilters = sessionStorage.getItem(PBI_REPORT_FILTERS_STORAGE_KEY);
        return !!storageFilters;
    }

    public static applyStoredFilters(currentFilters: models.IFilter[]) {
        if (!currentFilters || currentFilters.length === 0) {
            return currentFilters;
        }

        const storageFilters = sessionStorage.getItem(PBI_REPORT_FILTERS_STORAGE_KEY);
        if (!storageFilters) {
            return currentFilters;
        }
        const savedFilters = JSON.parse(storageFilters) as models.IFilter[];

        const appliedFilters: models.IFilter[] = [];
        for (let i = 0; i < currentFilters.length; i++) {
            const currentFilter = currentFilters[i];
            //Filters must the same display name
            const savedFilter = savedFilters.find(f => f?.displaySettings?.displayName === currentFilter.displaySettings?.displayName);
            if (savedFilter) {
                //Filters are the same schema BasicFilter
                if (currentFilter.$schema === PBI_REPORT_BASIC_FILTER_SCHEMA && savedFilter.$schema === PBI_REPORT_BASIC_FILTER_SCHEMA) {
                    const appliedFilter = (currentFilter as models.IBasicFilter);
                    //Apply saved values
                    appliedFilter.operator = (savedFilter as models.IBasicFilter).operator;
                    appliedFilter.values = (savedFilter as models.IBasicFilter).values;
                    appliedFilters.push(appliedFilter);
                } else {
                    appliedFilters.push(currentFilter);
                }
            }
            else {
                appliedFilters.push(currentFilter);
            }
        }

        return appliedFilters;
    }

    public static applyDefaultFilters(currentFilters: models.IFilter[], defaultFilters: IDefaultReportFilter[]) {
        if (!currentFilters || currentFilters.length === 0 || !defaultFilters || defaultFilters.length === 0) {
            return currentFilters;
        }

        const appliedFilters: models.IFilter[] = [];
        for (let i = 0; i < currentFilters.length; i++) {
            const currentFilter = currentFilters[i];
            //Filters must the same display name
            const defaultFilter = defaultFilters.find(f => f.displayName === currentFilter.displaySettings?.displayName);
            if (defaultFilter) {
                //Filters are the same schema BasicFilter
                if (currentFilter.$schema === PBI_REPORT_BASIC_FILTER_SCHEMA) {
                    const appliedFilter = (currentFilter as models.IBasicFilter);
                    //Apply default values
                    appliedFilter.operator = "In";
                    appliedFilter.values = defaultFilter.values;
                    appliedFilters.push(appliedFilter);
                } else {
                    appliedFilters.push(currentFilter);
                }
            }
            else {
                appliedFilters.push(currentFilter);
            }
        }

        return appliedFilters;
    }

    public static applyStoredVisualFilters(currentFilters: models.IFilter[]) {
        if (!currentFilters || currentFilters.length === 0) {
            return currentFilters;
        }

        const storageFilters = sessionStorage.getItem(PBI_REPORT_VISUAL_FILTERS_STORAGE_KEY);
        if (!storageFilters) {
            return currentFilters;
        }

        const savedFilters = JSON.parse(storageFilters) as models.IFilter[];

        const appliedFilters: models.IFilter[] = [];
        for (let i = 0; i < currentFilters.length; i++) {
            const currentFilter = currentFilters[i];
            //Filters must the same display name
            const savedFilter = savedFilters.find(f => f.displaySettings?.displayName === currentFilter.displaySettings?.displayName);
            if (savedFilter) {
                //Filters are the same schema AdvancedFilter
                if (currentFilter.$schema === PBI_REPORT_ADVANCED_FILTER_SCHEMA && savedFilter.$schema === PBI_REPORT_ADVANCED_FILTER_SCHEMA) {
                    const appliedFilter = (currentFilter as models.IAdvancedFilter);
                    //Apply saved conditions
                    appliedFilter.conditions = (savedFilter as models.IAdvancedFilter).conditions;
                    appliedFilters.push(appliedFilter);
                } else {
                    appliedFilters.push(currentFilter);
                }
            }
            else {
                appliedFilters.push(currentFilter);
            }
        }
        return appliedFilters;
    }
};

